import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtenerPagos() {
    var url = ConfigAPI.baseURL + "payments-erp" + Session.getToken();
    return instance.get(url);
  },
  mostrarPagos(id) {
    var url = ConfigAPI.baseURL + "payments-erp/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarPagos(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "payments-erp" + Session.getToken();
    return instance.post(url,params);
  },
  editarPagos(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "payments-erp/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarPagos(id) {
    var url = ConfigAPI.baseURL + "payments-erp/"+ id + Session.getToken();
    return instance.delete(url);
  },     
  filtrarPagos(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "payments-erp/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  
  
  imputarPagos(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "payments-erp/impute/purchase" + Session.getToken();
    return instance.post(url,params);
  }, 
  desimputarPagos(data) {
    var params = data
    var url = ConfigAPI.baseURL + "payments-erp/disprove/purchase" + Session.getToken();
    return instance.post(url,params);    
  },

  filtrarPendienteImputar(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "payments-erp/filter/pendingImpute" + Session.getToken();
    return instance.post(url,params);
  },    

  obtenerVoucherById(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/id" + Session.getToken();
    return instance.post(url,params);
  },
  obtenerVoucherBySupplier(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sales-base/get-voucher/supplier" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerPuntosVentas() {
    var url = ConfigAPI.baseURL + "sales-points-sales" + Session.getToken();
    return instance.get(url);
  }, 
  obtenerProveedores() {
    var url = ConfigAPI.baseURL + "suppliers" + Session.getToken();
    return instance.get(url);
  }, 
  
  filtrarCurrentsAccountsBalance(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "purchase-currents-accounts/filter/query/total" + Session.getToken();
    return instance.post(url,params);
  },      
}

export default servicesAPI;
